$(function () {


    $("form[name='contact-form']").validate({
        rules: {
          email: {
            required: true,
            email: true
          },
          name: {
            required: true
          },
          phone: {
            required: true
          },
          message: {
            required: true
          },
        },
        messages: {
            email: "Ingrese un e-mail.",
            name: "Ingrese nombre y apellido.",
            phone: "Ingrese un teléfono valido.",
            message: "Ingrese un mensaje.",
        },
        submitHandler: function(form) {
    
            var $button = $('.button-contact');
            var messages = "Enviando...";
            $button.html(messages);
            $button.addClass('loading');

            $.ajax({
                type  : "POST",
                url   : 'mail/mail.php',
                data  : $(form).serialize(),
                cache       : false,
                dataType    : 'json',
                method: 'POST',
                success: function ( data, textStatus, jQxhr ) {

                    console.log('data',data);

                    var messages = "Gracias";
                    $button.html(messages);
                    $button.removeClass('loading');
                    $button.addClass('active');

                },
                error: function ( jqXHR, textStatus, errorThrown ) {
                    console.log(jqXHR);
                    console.log(textStatus);
                    console.log(errorThrown);
                }
            });
      
        }
    });

    var windowsWidth = $( window ).width();

    var mySwiperGallery = new Swiper ('#gallery', {
        loop: true,
        autoplay: false,
        speed: 1000,
        effect: 'fade',
        pagination: {
            el: '#gallery .swiper-pagination',
        },
        navigation: {
            nextEl: '#gallery .swiper-button-next',
            prevEl: '#gallery .swiper-button-prev',
        }
    });

    if ( $('body').hasClass('landing') ) {

        var videoPlayerOptions = {
            url: 'https://vimeo.com/404838568',
            color: '004c50',
            background: true,
            byline: false,
            title: false
        };

        var videoPlayer = new Vimeo.Player('videoPlayer', videoPlayerOptions);

    }


    var mySwiperGallery = new Swiper ('#ubicacionGallery', {
        loop: true,
        autoplay: true,
        speed: 1000,
        effect: 'fade',
        pagination: {
            el: '#ubicacionGallery .swiper-pagination',
        },
        navigation: {
            nextEl: '#ubicacionGallery .swiper-button-next',
            prevEl: '#ubicacionGallery .swiper-button-prev',
        }
    });


    //UNIDADES!!
    if ( $('body').hasClass('unidades') && windowsWidth >= 991 ) {
        var allowTouchMove = false;
    }else{
        var allowTouchMove = true;
    }


    var swiperUnidad1 = new Swiper ('#unidad-1 .swiper-container', {
        autoplay: false,
        speed: 400,
        effect: 'fade',
        preventClicks: allowTouchMove,
        preventClicksPropagation: allowTouchMove,
        shortSwipes:allowTouchMove,
        longSwipes:allowTouchMove,
        allowTouchMove:allowTouchMove
    });
    $('#unidad-1 .nav-unidades-1').click(function(){ swiperUnidad1.slideTo(0, 400, false); });
    $('#unidad-1 .nav-unidades-2').click(function(){ swiperUnidad1.slideTo(1, 400, false); });

    var swiperUnidad2 = new Swiper ('#unidad-2 .swiper-container', {
        autoplay: false,
        speed: 400,
        effect: 'fade',
        preventClicks: allowTouchMove,
        preventClicksPropagation: allowTouchMove,
        shortSwipes:allowTouchMove,
        longSwipes:allowTouchMove,
        allowTouchMove:allowTouchMove
    });
    $('#unidad-2 .nav-unidades-1').click(function(){ swiperUnidad2.slideTo(0, 400, false); });
    $('#unidad-2 .nav-unidades-2').click(function(){ swiperUnidad2.slideTo(1, 400, false); });

    var swiperUnidad3 = new Swiper ('#unidad-3 .swiper-container', {
        autoplay: false,
        speed: 400,
        effect: 'fade',
        preventClicks: allowTouchMove,
        preventClicksPropagation: allowTouchMove,
        shortSwipes:allowTouchMove,
        longSwipes:allowTouchMove,
        allowTouchMove:allowTouchMove
    });
    $('#unidad-3 .nav-unidades-1').click(function(){ swiperUnidad3.slideTo(0, 400, false); });
    $('#unidad-3 .nav-unidades-2').click(function(){ swiperUnidad3.slideTo(1, 400, false); });

    var swiperUnidad4 = new Swiper ('#unidad-4 .swiper-container', {
        autoplay: false,
        speed: 400,
        effect: 'fade',
        preventClicks: allowTouchMove,
        preventClicksPropagation: allowTouchMove,
        shortSwipes:allowTouchMove,
        longSwipes:allowTouchMove,
        allowTouchMove:allowTouchMove
    });
    $('#unidad-4 .nav-unidades-1').click(function(){ swiperUnidad4.slideTo(0, 400, false); });
    $('#unidad-4 .nav-unidades-2').click(function(){ swiperUnidad4.slideTo(1, 400, false); });
    $('#unidad-4 .nav-unidades-3').click(function(){ swiperUnidad4.slideTo(2, 400, false); });
    $('#unidad-4 .nav-unidades-4').click(function(){ swiperUnidad4.slideTo(3, 400, false); });

    var swiperUnidad5 = new Swiper ('#unidad-5 .swiper-container', {
        autoplay: false,
        speed: 400,
        effect: 'fade',
        preventClicks: allowTouchMove,
        preventClicksPropagation: allowTouchMove,
        shortSwipes:allowTouchMove,
        longSwipes:allowTouchMove,
        allowTouchMove:allowTouchMove
    });
    $('#unidad-5 .nav-unidades-1').click(function(){ swiperUnidad5.slideTo(0, 400, false); });
    $('#unidad-5 .nav-unidades-2').click(function(){ swiperUnidad5.slideTo(1, 400, false); });
    $('#unidad-5 .nav-unidades-3').click(function(){ swiperUnidad5.slideTo(2, 400, false); });
    $('#unidad-5 .nav-unidades-4').click(function(){ swiperUnidad5.slideTo(3, 400, false); });


    //ZOOOOOM
    if ( $('body').hasClass('unidades') && windowsWidth >= 991 ) {

        //2_1
        var svg2_1 = svgPanZoom('#svg2_1', { zoomEnabled:false, controlIconsEnabled:false, fit:true, center:true, minZoom:0.1 });
        svg2_1.zoom(1);
        var svg2_1_parent = $('#svg2_1').parent();
        svg2_1_parent.find('.zoom-in').bind("click", function(ev){ ev.preventDefault(); svg2_1.zoomIn(); });
        svg2_1_parent.find('.zoom-out').bind("click", function(ev){ ev.preventDefault(); svg2_1.zoomOut(); });
        //2_2
        var svg2_2 = svgPanZoom('#svg2_2', { zoomEnabled:false, controlIconsEnabled:false, fit:true, center:true, minZoom:0.1 });
        svg2_2.zoom(1);
        var svg2_2_parent = $('#svg2_2').parent();
        svg2_2_parent.find('.zoom-in').bind("click", function(ev){ ev.preventDefault(); svg2_2.zoomIn(); });
        svg2_2_parent.find('.zoom-out').bind("click", function(ev){ ev.preventDefault(); svg2_2.zoomOut(); });

        //2p_1
        var svg2p_1 = svgPanZoom('#svg2p_1', { zoomEnabled:false, controlIconsEnabled:false, fit:true, center:true, minZoom:0.1 });
        svg2p_1.zoom(1);
        var svg2p_1_parent = $('#svg2p_1').parent();
        svg2p_1_parent.find('.zoom-in').bind("click", function(ev){ ev.preventDefault(); svg2p_1.zoomIn(); });
        svg2p_1_parent.find('.zoom-out').bind("click", function(ev){ ev.preventDefault(); svg2p_1.zoomOut(); });
        //2p_2
        var svg2p_2 = svgPanZoom('#svg2p_2', { zoomEnabled:false, controlIconsEnabled:false, fit:true, center:true, minZoom:0.1 });
        svg2p_2.zoom(1);
        var svg2p_2_parent = $('#svg2p_2').parent();
        svg2p_2_parent.find('.zoom-in').bind("click", function(ev){ ev.preventDefault(); svg2p_2.zoomIn(); });
        svg2p_2_parent.find('.zoom-out').bind("click", function(ev){ ev.preventDefault(); svg2p_2.zoomOut(); });

        //3_1
        var svg3_1 = svgPanZoom('#svg3_1', { zoomEnabled:false, controlIconsEnabled:false, fit:true, center:true, minZoom:0.1 });
        svg3_1.zoom(1);
        var svg3_1_parent = $('#svg3_1').parent();
        svg3_1_parent.find('.zoom-in').bind("click", function(ev){ ev.preventDefault(); svg3_1.zoomIn(); });
        svg3_1_parent.find('.zoom-out').bind("click", function(ev){ ev.preventDefault(); svg3_1.zoomOut(); });
        //3_2
        var svg3_2 = svgPanZoom('#svg3_2', { zoomEnabled:false, controlIconsEnabled:false, fit:true, center:true, minZoom:0.1 });
        svg3_2.zoom(1);
        var svg3_2_parent = $('#svg3_2').parent();
        svg3_2_parent.find('.zoom-in').bind("click", function(ev){ ev.preventDefault(); svg3_2.zoomIn(); });
        svg3_2_parent.find('.zoom-out').bind("click", function(ev){ ev.preventDefault(); svg3_2.zoomOut(); });

        //4_1
        var svg3p_1 = svgPanZoom('#svg3p_1', { zoomEnabled:false, controlIconsEnabled:false, fit:true, center:true, minZoom:0.1 });
        svg3p_1.zoom(1);
        var svg3p_1_parent = $('#svg3p_1').parent();
        svg3p_1_parent.find('.zoom-in').bind("click", function(ev){ ev.preventDefault(); svg3p_1.zoomIn(); });
        svg3p_1_parent.find('.zoom-out').bind("click", function(ev){ ev.preventDefault(); svg3p_1.zoomOut(); });
        //4_2
        var svg3p_2 = svgPanZoom('#svg3p_2', { zoomEnabled:false, controlIconsEnabled:false, fit:true, center:true, minZoom:0.1 });
        svg3p_2.zoom(1);
        var svg3p_2_parent = $('#svg3p_2').parent();
        svg3p_2_parent.find('.zoom-in').bind("click", function(ev){ ev.preventDefault(); svg3p_2.zoomIn(); });
        svg3p_2_parent.find('.zoom-out').bind("click", function(ev){ ev.preventDefault(); svg3p_2.zoomOut(); });
        //4_3
        var svg3p_3 = svgPanZoom('#svg3p_3', { zoomEnabled:false, controlIconsEnabled:false, fit:true, center:true, minZoom:0.1 });
        svg3p_3.zoom(1);
        var svg3p_3_parent = $('#svg3p_3').parent();
        svg3p_3_parent.find('.zoom-in').bind("click", function(ev){ ev.preventDefault(); svg3p_3.zoomIn(); });
        svg3p_3_parent.find('.zoom-out').bind("click", function(ev){ ev.preventDefault(); svg3p_3.zoomOut(); });
        //4_4
        var svg3p_4 = svgPanZoom('#svg3p_4', { zoomEnabled:false, controlIconsEnabled:false, fit:true, center:true, minZoom:0.1 });
        svg3p_4.zoom(1);
        var svg3p_4_parent = $('#svg3p_4').parent();
        svg3p_4_parent.find('.zoom-in').bind("click", function(ev){ ev.preventDefault(); svg3p_4.zoomIn(); });
        svg3p_4_parent.find('.zoom-out').bind("click", function(ev){ ev.preventDefault(); svg3p_4.zoomOut(); });

        //5_1
        var svg5_1 = svgPanZoom('#svg5_1', { zoomEnabled:false, controlIconsEnabled:false, fit:true, center:true, minZoom:0.1 });
        svg5_1.zoom(1);
        var svg5_1_parent = $('#svg5_1').parent();
        svg5_1_parent.find('.zoom-in').bind("click", function(ev){ ev.preventDefault(); svg5_1.zoomIn(); });
        svg5_1_parent.find('.zoom-out').bind("click", function(ev){ ev.preventDefault(); svg5_1.zoomOut(); });
        //5_2
        var svg5_2 = svgPanZoom('#svg5_2', { zoomEnabled:false, controlIconsEnabled:false, fit:true, center:true, minZoom:0.1 });
        svg5_2.zoom(1);
        var svg5_2_parent = $('#svg5_2').parent();
        svg5_2_parent.find('.zoom-in').bind("click", function(ev){ ev.preventDefault(); svg5_2.zoomIn(); });
        svg5_2_parent.find('.zoom-out').bind("click", function(ev){ ev.preventDefault(); svg5_2.zoomOut(); });
        //5_3
        var svg5_3 = svgPanZoom('#svg5_3', { zoomEnabled:false, controlIconsEnabled:false, fit:true, center:true, minZoom:0.1 });
        svg5_3.zoom(1);
        var svg5_3_parent = $('#svg5_3').parent();
        svg5_3_parent.find('.zoom-in').bind("click", function(ev){ ev.preventDefault(); svg5_3.zoomIn(); });
        svg5_3_parent.find('.zoom-out').bind("click", function(ev){ ev.preventDefault(); svg5_3.zoomOut(); });
        //5_4
        var svg5_4 = svgPanZoom('#svg5_4', { zoomEnabled:false, controlIconsEnabled:false, fit:true, center:true, minZoom:0.1 });
        svg5_4.zoom(1);
        var svg5_4_parent = $('#svg5_4').parent();
        svg5_4_parent.find('.zoom-in').bind("click", function(ev){ ev.preventDefault(); svg5_4.zoomIn(); });
        svg5_4_parent.find('.zoom-out').bind("click", function(ev){ ev.preventDefault(); svg5_4.zoomOut(); });
    }


    //HOME PARALLAX
    $('.section-1.parallax').parallax({
        imageSrc: 'assets/images/home/bg_section-1.jpg',
        speed: 1.2,
        positionY: 'top'
    });
    $('.section-9.parallax').parallax({
        imageSrc: 'assets/images/home/bg_section-9.jpg',
        speed: 0.2
    });

    //PROYECTO PARALLAX
    $('.section-full-image.proyecto-1').parallax({
        imageSrc: 'assets/images/planos/proyecto-1.jpg',
        speed: 0.2
    });
    $('.section-full-image.proyecto-2').parallax({
        imageSrc: 'assets/images/planos/proyecto-2.jpg',
        speed: 0.2
    });
    $('.section-full-image.proyecto-3').parallax({
        imageSrc: 'assets/images/planos/proyecto-3.jpg',
        speed: 0.2
    });
    $('.section-full-image.proyecto-4').parallax({
        imageSrc: 'assets/images/planos/proyecto-4.jpg',
        speed: 0.2
    });
    $('.section-full-image.proyecto-5').parallax({
        imageSrc: 'assets/images/planos/proyecto-5.jpg',
        speed: 0.2
    });

    $('html').smoothScroll(900);

    $('header').midnight();

    //MOBILE MENU
    $('.mobile-btn').bind('click', function(ev){
        ev.preventDefault();
        $('.header-mobile').toggleClass('active');
    });

    //
    $('.concepto-info h4').bind('click', function(ev){
        ev.preventDefault();
        $(this).parent().toggleClass('active');
    });

    //
    $('.border-bottom.col-sm-5.col-md-2 span').bind('click', function(ev){
        ev.preventDefault();
        //$('.border-bottom.col-sm-5.col-md-2').removeClass('active');
        //$('.border-bottom.col-sm-7').prev().height('auto');

        var parent = $(this).parent();
        parent.toggleClass('active');

        if(parent.hasClass('active')){
            var contentHeight = parent.next().find('span').height();
            parent.next().height(contentHeight+21);
        }else{
            parent.next().height(0);
        }

    });

});
